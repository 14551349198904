import { template as template_9b2741f0f0a04c64abde6f0f9474b2af } from "@ember/template-compiler";
import Component from "@glimmer/component";
import { on } from "@ember/modifier";
import { action } from "@ember/object";
import { i18n } from "discourse-i18n";
export default class ActivationEmailForm extends Component {
    @action
    newEmailChanged(event) {
        this.args.updateNewEmail(event.target.value);
    }
    static{
        template_9b2741f0f0a04c64abde6f0f9474b2af(`
    <p>{{i18n "login.provide_new_email"}}</p>
    <input
      {{on "input" this.newEmailChanged}}
      value={{@email}}
      type="text"
      class="activate-new-email"
    />
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
